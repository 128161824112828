<template>
  <div class="space-y-2">
    <Swiper
      :style="{
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      }"
      loop
      :breakpoints="productSlideBreakpoints"
      :navigation="false"
      :thumbs="{ swiper: thumbsSwiper }"
      :zoom="isMobile||isTablet"
      :modules="[Navigation, Pagination, Scrollbar, A11y, Thumbs, Zoom]"
      class="mySwiper bg-white rounded-lg border border-lightgray"
    >
      <SwiperSlide
        class="flex flex-col items-center justify-center relative product-image-wrapper"
        v-for="(image, id) in selectedVariant.images"
        :key="id"
        >
        <NuxtImg
          width="1200"
          height="1200"
          fit="outside"
          loading="lazy"
          format="webp"
          :placeholder="[50, 50, 93, 3]"
          :quality="98"
          :alt="selectedVariant?.title"
          :src="getImage(image.medium)"
          class="object-contain aspect-square mx-auto"
          @click="open(image)"
          />
        <div
          v-if="selectedVariant?.isFreeShipping"
          class="flex absolute z-0 justify-center text-center items-end top-0 left-0"
        >
          <span class="py-2 px-4 text-sm bg-lightgray-50 w-full rounded-br-lg flex items-center justify-center">
            <FastIcon class="h-5 w-5 mr-2 fill-primary" />
            <span>{{ $t('app.free-shipping') }}</span>
          </span>
        </div>
      </SwiperSlide>
      <SwiperSlide v-if="selectedVariant?.images?.length <= 0" class="flex flex-col items-center justify-center relative product-image-wrapper border border-lightgray rounded-md">
        <div class="relative">
          <NuxtImg
            fit="outside"
            alt=""
            class="rounded-lg object-contain aspect-square"
            width="580"
            height="580"
            src="/images/no-image.png"
          />
        </div>
       </SwiperSlide>
    </Swiper>
    <Swiper
      @swiper="setThumbsSwiper"
      :spaceBetween="1"
      :slidesPerView="8"
      :freeMode="true"
      :watchSlidesProgress="true"
     :modules="[Thumbs]"
      class="mySwiper"
    >
      <SwiperSlide
      class="cursor-pointer bg-white rounded-md border border-lightgray"
      v-for="(image, id) in selectedVariant.images"
      :key="id"
        ><NuxtImg
          width="100"
          height="100"
          fit="outside"
          loading="lazy"
          format="webp"
          :placeholder="[50, 50, 93, 3]"
          :quality="98"
          class="object-contain aspect-square" :src="getImage(image.medium)" />
      </SwiperSlide>
      <SwiperSlide v-if="selectedVariant?.images?.length <= 0">
        <div class="relative">
          <NuxtImg
            fit="outside"
            alt=""
            class="border border-lightgray rounded-lg object-contain aspect-square"
            width="100"
            height="100"
            src="/images/no-image.png"
          />
        </div>
       </SwiperSlide>
    </Swiper>
    <ClientOnly>
      <ProductModal />
    </ClientOnly>
  </div>
</template>
<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Thumbs, Navigation, Pagination, Scrollbar, A11y, Zoom } from "swiper/modules";
import FastIcon from "~/assets/icons/fast.svg";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";

const openProductModal = useState("openProductModal", () => false);
const {isMobile, isTablet, isDesktop} = useDevice()

const {
  selectedVariant,
  product,
  productSlideBreakpoints
} = await useProduct()

const {
  selectedImage
} = useZoom()

const thumbsSwiper = ref(null);

const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper;
};

const open = (image) => {
  openProductModal.value = true
  selectedImage.value = image?.original;
}

</script>
